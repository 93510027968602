<template>
<div>
  <div class="page-banner">
    <img v-lazy="banner" class="img-center">
  </div>
  <div class="container padding-80">
    <div class="common-title">
      <h1>{{ $t("menu.contact") }}</h1>
      <div class="common-line"></div>
    </div>
    <Address :support_phone_numbers="support_phone_numbers"/>
    <div class="map">
      <iframe :src="map"  :key="map" style="border:0;"  allowfullscreen="" loading="eager" referrerpolicy="no-referrer-when-downgrade"></iframe>
<!--      给iframe增加一个动态的key,浏览器返回就可以正常使用 -->
    </div>


    <div class="padding-t-80">
      <div class="common-title">
        <h1>{{ $t("menu.inquiry") }}</h1>
        <div class="common-line"></div>
      </div>
      <inquiry-form :inquiryBg="inquiryBg" />
    </div>


  </div>
</div>
</template>

<script>
import Address from "@/views/supportDetail/Address";
import inquiryForm from "@/views/supportDetail/inquiryForm";

import {getContact, getSupport} from "@/network/main";

export default {
  name: "Contact",
  components:{
    Address,
    inquiryForm,
  },
  data(){
    return {
      banner:{},
      support_phone_numbers:[],
      map:{},
      inquiryBg:{},
    }
  },
  created() {
    getContact().then(res => {
      this.banner = res.data.data.banner;
      this.support_phone_numbers = res.data.data.address;
      this.map = res.data.data.contact_us.google_map;
    })

    getSupport().then(res => {
      this.inquiryBg = res.data.data.info.image_one;
    })

  },
}
</script>

<style scoped>
.map iframe{
  margin-top: 50px;
  width: 100%;
  height: 450px;
}
@media only screen and (min-width:0px) and (max-width:767px){
  .map iframe{
    margin-top: 30px;
    height: 300px;
  }
}

</style>