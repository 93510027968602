<template>
  <div class="address-list">
    <ul>
      <li v-for="item in support_phone_numbers" :key="item">
        <div class="address-img">
          <img :src="item.image">
        </div>
        <div class="address-txt">
          <h2>{{ item.title }}</h2>
          <h4>{{item.address}}</h4>
          <div class="address-txt-info">
            <p><b>{{ $t("techincal") }}</b></p>
            <p>{{ $t("tel") }}: {{ item.technical_support.tel }}</p>
            <p>{{ $t("fax") }}: {{item.technical_support.fax}}</p>
            <p>{{ $t("email") }}: {{item.technical_support.email}}</p>
          </div>
          <div class="address-txt-info">
            <p><b>{{ $t("general") }}</b></p>
            <p>{{ $t("tel") }}: {{ item.general_enquire.tel }}</p>
            <p>{{ $t("fax") }}: {{item.general_enquire.fax}}</p>
            <p>{{ $t("email") }}: {{item.general_enquire.email}}</p>
          </div>
        </div>
      </li>
    </ul>
 </div>

</template>

<script>
export default {
  name: "Adress",
  props:{
    support_phone_numbers: {
      default() {
        return []
      }
    }
  },
}
</script>

<style scoped>
.address-list{
  margin-top: 50px;
}

.address-list ul li{
  display: flex;
  align-items:  center;
  justify-content: space-between;
  margin-top: 50px;
}

.address-img{
  width: 470px;
  margin-right: 30px;
}

.address-txt{
  width: calc(100% - 500px);
  color: #000;
}

.address-txt-info{
  padding-top: 10px;
}
.address-txt h4{
  padding-top: 10px;
  font-weight: normal;
}

.address-txt-info b{
  padding-bottom: 5px;
  display: block;

}

.address-txt-info p{
  line-height: 1.6;

}
@media only screen and (min-width:0px) and (max-width:767px){

  .address-list{
     margin-top: 20px;
  }

  .address-list ul li{
    flex-wrap: wrap;
    margin-top: 20px;
  }
  .address-img{
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .address-txt {
    width: 100%;

  }

  .address-txt h2{
    font-size: 20px;
  }



}


</style>