<template>
<div class="form-bg"  :style="{ 'background': 'url('+ inquiryBg + ') no-repeat center center'}">
<form>
  <div class="in-form-li">
    <div class="in-form-item">
      <p>{{ $t("name") }}</p>
      <input type="text" class="form-control" v-model="name" name="name" id="name" autocomplete="off" >
    </div>
    <div class="in-form-item">
      <p>{{ $t("email") }}</p>
      <input type="text" class="form-control" v-model="email" name="email" id="email" autocomplete="off">
    </div>
  </div>
  <div class="in-form-li">
    <div class="in-form-item">
      <p>{{ $t("phone") }}</p>
      <input type="number" class="form-control" v-model="phone" name="phone" id="phone"  autocomplete="off">
    </div>
    <div class="in-form-item">
      <p>{{ $t("topic") }}</p>
      <input type="text" class="form-control" v-model="topic" name="topic" id="topic" autocomplete="off">
    </div>
  </div>
  <div class="form-msg">
    <p>{{ $t("message") }}</p>
    <textarea v-model="message" name="message" id="message" />
  </div>
  <div class="in-form-btn">
    <button type="button" class="submit"  @click="onSubmit()">{{ $t("submit") }}</button>
  </div>
</form>
</div>

  <!-- res 200 pop -->
  <div class="pop" v-if="success">
    {{ $t("success") }}
  </div>
  <!-- err 500 pop -->
  <div class="pop" v-if="miss">
    {{ $t("failed") }}
  </div>



</template>

<script>

import axios from  'axios';
export default {
  name: "inquiryForm",
  props:{
    inquiryBg: {
      default() {
        return {}
      }
    }
  },
  data(){
    return {
      name:'',
      email:'',
      phone:'',
      topic:'',
      message:'',
      success: false,
      miss: false,
    }
  },
  methods: {
    onSubmit() {
      //

      function shake(elemId) {
        let elem = document.getElementById(elemId)
        if (elem) {
          elem.classList.add('shake')
          elem.classList.add('redBorder')
          setTimeout(()=>{ elem.classList.remove('shake') }, 800)
        }
      }

      //判断

      if (this.name == ""){
        shake('name')
        return false;
      }


      if(this.email !== "") {
        let reg=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if(!reg.test(this.email)){
          shake('email')
          return false;
        }
      }else {
        shake('email')
        return false;
      }


      // if (this.phone !== "") {
      //   if (!/^([09]\d{5})$/.test(this.phone)) {
      //     shake('phone')
      //     return false;
      //   }
      // }else {
      //   shake('phone')
      //   return false;
      // }

      if (this.phone == "") {
        shake('topic')
        return false;
      }


      if (this.topic == ""){
        shake('topic')
        return false;
      }

      if (this.message == ''){
        shake('message')
        return false;
      }


      //params  URLSearchParams对象
      let params = new URLSearchParams();
      params.append('name',this.name)
      params.append('email',this.email)
      params.append('phone',this.phone)
      params.append('topic',this.topic)
      params.append('message',this.message)

      axios.post('https://api.layer1hk.com/api/support_inquiry_form', params).then((res)=>{
        console.log(res)
        this.success = true
        if (this.success) {
          setTimeout(() => {
            this.success = false;
            this.$router.push('/');

          }, 2000)

        }
      }).catch(err=> {
        console.log(err)
        this.miss = true
        if (this.miss) {
          setTimeout(() => {
            this.miss = false
          }, 2000)
        }
      })
    },
  }

}
</script>

<style scoped>



.form-bg{
  margin-top: 50px;
  padding: 20px;
 background-size: 100%;
}

.in-form-li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0;
}

.in-form-item{
  width: 50%;
  padding: 0 20px;
}

.in-form-item p,
.form-msg p{
  font-size: 18px;
  color: #fff;
  padding-bottom: 10px;
}

.form-control{
  width: 100%;
  height: 44px;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.form-msg{
  padding: 0 20px;

}

.form-msg textarea{
  width: 100%;
  color: #fff;
  background: rgba(255, 255, 255, 0.2);
}

.in-form-btn{
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  margin-top: 20px;
}

.in-form-btn button{
  background: var(--color-background);
  margin: 10px 0;
}


@media only screen and (min-width:0px) and (max-width:767px){
  .in-form-li{
    flex-wrap: wrap;
    margin: 0;
  }

  .in-form-item{
    margin-bottom: 15px;
  }


  .form-bg{
    margin-top:20px;
    padding: 20px;
  }

  .in-form-item, .form-msg{
    width: 100%;
    padding: 0;
  }

  .in-form-btn{
    justify-content: center;
  }

}

</style>